export const ROUTES = {
  LANDING_PAGE: {
    title: 'Crave',
    route: '/',
  },
  SIGN_UP: {
    title: 'Crave - Sign up',
    route: '/signup',
  },
  SIGN_IN: {
    title: 'Crave - Sign in',
    route: '/signin',
  },
  MENU: {
    title: 'Crave - Menu',
    route: '/menu',
  },
  FORGOT_PASSWORD: {
    title: 'Crave - Forgot Password',
    route: '/forgot-password',
  },
  CREATE_NEW_PASSWORD: {
    title: 'Crave - Create new password',
    route: '/create-new-password',
  },
  MY_ORDERS: {
    title: 'Crave - My Orders',
    route: '/my-orders',
  },
  MY_ORDER: {
    title: 'Crave - My Order',
    route: '/my-orders/:id',
  },
  MY_PROFILE: {
    title: 'Crave - My Profile',
    route: '/my-profile',
  },
  HELP: {
    title: 'Crave - Help',
    route: '/help',
  },
  CHECKOUT: {
    title: 'Crave - Checkout',
    route: '/checkout',
  },
  INVOICE: {
    title: 'Crave - Invoice',
    route: '/invoice-preview-only',
  },
  DEVELOPER_TOOLS: {
    title: 'Crave - Developer Tools',
    route: '/developer-tools',
  },
};

export const ACCOUNT_ROUTES = [
  ROUTES.SIGN_UP,
  ROUTES.SIGN_IN,
  ROUTES.FORGOT_PASSWORD,
  ROUTES.CREATE_NEW_PASSWORD,
];
export const PRIVATE_ROUTES = [
  ROUTES.MY_ORDERS,
  ROUTES.MY_PROFILE,
  ROUTES.HELP,
  ROUTES.CHECKOUT,
  ROUTES.MY_ORDER,
  ROUTES.INVOICE,
];
