export enum Env {
  STAGING = 'staging',
  RELEASE = 'release',
  DEMO = 'demo',
  PRODUCTION = 'production',
}

export const CUSTOM_ENV = process.env.REACT_APP_CUSTOM_ENV as Env;
export const isStaging = CUSTOM_ENV === Env.STAGING;
export const isProduction = CUSTOM_ENV === Env.PRODUCTION;
export const GOOGLE_MAPS_API_KEY = process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY;
