import {
  CheckAddressDocument,
  CheckAddressQuery,
} from '@codegen/generated/graphql';
import { getApolloClient, GetHeadersProps } from '@lib/apollo';
import { auth } from '@lib/firebase';
import { DashboardMenuItem } from '@utils/types';

import { dishTypeFilterValues, allergyFilterValues } from './constants';
import { OrderSummary, OverallSummary } from './types';

export const logoutAction = (props: GetHeadersProps) => async () => {
  const client = getApolloClient(props);
  client.resetStore();
};

export const signUp = async ({
  email,
  password,
}: {
  email: string;
  password: string;
}) => auth.createUserWithEmailAndPassword(email, password);

export const updateEmail = async (email: string) =>
  auth.currentUser?.updateEmail(email);

export const getCurrentUser = () => auth.currentUser;

export const deleteUser = async () => auth.currentUser?.delete();

export const resetPassword = ({ email }: { email: string }) =>
  auth.sendPasswordResetEmail(email);

// function that replaces the parameters of the route path with the arguments of the function using regex
// https://stackoverflow.com/a/52899009
export const getRoute = (path: string, ...args: string[]) => {
  let count = -1;

  return path.replace(/:[a-zA-Z?]+/g, (match) => {
    count += 1;

    return args[count] !== undefined ? args[count] : match;
  });
};

export const checkAddress = async (
  props: GetHeadersProps,
  facilityId: string,
  placeId: string,
) => {
  const client = getApolloClient(props);
  const response = await client.query<CheckAddressQuery>({
    fetchPolicy: 'network-only',
    query: CheckAddressDocument,
    variables: {
      placeId,
    },
  });

  return response?.data?.customerDeliveryInfos?.find(
    (deliveryInfo) =>
      deliveryInfo.facilityId === facilityId && deliveryInfo.isDeliverable,
  );
};

export const isEmailValid = (email: string) => {
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return re.test(String(email).toLowerCase());
};

export const orderSummary: OrderSummary = (
  { subtotal, delivery, taxFees, promoDiscount, giftCardsDiscount, total },
  isDelivery,
  gratuity,
) => {
  const { value = 0, type = '' } = gratuity ?? {};
  const typeFlat = type === 'flat';

  const subtotalAfterDiscounts = subtotal - promoDiscount;
  const cleanSubtotal = subtotalAfterDiscounts > 0 ? subtotalAfterDiscounts : 0;

  const calculatedGratuity = parseFloat(
    ((Number(value) * cleanSubtotal) / 100).toFixed(2),
  );
  const gratuityTotal = typeFlat ? value : calculatedGratuity;
  const appliedGratuity = isDelivery ? Number(gratuityTotal) : 0;

  const orderCost: OverallSummary = {
    subtotal,
    taxFees,
    delivery,
    promoDiscount,
    giftCardsDiscount,
    total: total + appliedGratuity,
    tip: appliedGratuity,
  };

  return orderCost;
};

// https://gist.github.com/telekosmos/3b62a31a5c43f40849bb#gistcomment-2341219
export const uniqArray = (arr: any[]) =>
  [...new Set(arr.map((o) => JSON.stringify(o)))].map((s) => JSON.parse(s));

export const objectsAreSame = (x: any, y: any) => {
  let objectsAreSame = true;
  for (const propertyName in x) {
    if (x[propertyName] !== y[propertyName]) {
      objectsAreSame = false;
      break;
    }
  }

  return objectsAreSame;
};

export const dishTypeFilterItems = (
  items: DashboardMenuItem[],
  filters: string[],
) => {
  const allergyFilters = allergyFilterValues
    .filter((allergyFilter) => filters.includes(allergyFilter.value))
    .map((filter) => filter.value);

  const dishTypeFilters = dishTypeFilterValues
    .filter((dishTypeFilter) => filters.includes(dishTypeFilter.value))
    .map((filter) => filter.value);

  let itemsToDisplay;
  //both dish type and allergy filters
  if (dishTypeFilters.length > 0 && allergyFilters.length > 0) {
    itemsToDisplay = items.filter(
      (item) =>
        dishTypeFilters.every((dishFilter) =>
          item.dishTypes.includes(dishFilter),
        ) &&
        allergyFilters.every((allergyFilter) =>
          item.allergies.includes(allergyFilter),
        ),
    );
  }

  //only dish type filters
  else if (dishTypeFilters.length > 0 && allergyFilters.length === 0) {
    itemsToDisplay = items.filter((item) =>
      dishTypeFilters.every((dishFilter) =>
        item.dishTypes.includes(dishFilter),
      ),
    );
  }

  //only allergy filters
  else if (dishTypeFilters.length === 0 && allergyFilters.length > 0) {
    itemsToDisplay = items.filter((item) =>
      allergyFilters.every((allergyFilter) =>
        item.allergies.includes(allergyFilter),
      ),
    );
    //Defaults to all items when no filters are applied
  } else {
    itemsToDisplay = items;
  }

  return itemsToDisplay;
};

export const fbqDefined = () => {
  return typeof window !== 'undefined' && typeof window.fbq === 'function';
};
