import { createContext, FC, useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';

import { message } from 'antd';
import moment, { Moment } from 'moment-timezone';
import { useRouter } from 'next/router';

import {
  useUserQuery,
  OrderMethod,
  useCustomerCartCostsQuery,
  useCustomerCartQuery,
} from '@codegen/generated/graphql';
import { ERROR_MESSAGE, INITIAL_CHECKOUT_ERRORS } from '@utils/constants';
import { orderSummary } from '@utils/helpers';
import {
  CATEGORY_OPTIONS,
  CheckoutErrors,
  DeliverTo,
  GeoAddress,
  GratuityType,
  KITCHEN_OPTIONS,
  Platform,
} from '@utils/types';

import { INITIAL_VALUES, ORDER_SUMMARY_DEFAULT_VALUES } from './constants';
import {
  getDefaultAddress,
  withUpdateSessionStorage,
  getInitialValues,
  getExistingAddress,
  UrlKey,
} from './helpers';
import { DeliveryTime, OrderDeliveryTimeSpecialOption, Props } from './types';

export const AppContext = createContext<Props>(INITIAL_VALUES);

// TODO: Refactor the entire file, the re-renders are massive
const AppContextProvider: FC<{ platform: Platform; extraParams: string }> = ({
  children,
  platform,
  extraParams,
}) => {
  const { push, query } = useRouter();
  const { data } = useUserQuery();

  const me = data?.me;

  const {
    data: customerCartData,
    refetch: customerCartQuery,
    loading: customerCartLoading,
  } = useCustomerCartQuery();

  const customerCart = customerCartData?.me?.customerInfo?.cart;
  const cartItemsCount =
    customerCartData?.me?.customerInfo?.cart?.items.reduce(
      (acc, curr) => acc + curr.quantity,
      0,
    ) ?? 0;
  const isMobile = useMediaQuery({ query: '(max-width: 450px)' });

  const initialValues = getInitialValues();

  const [kitchenId, setKitchenId] = useState<string>(initialValues.kitchenId);
  const [categoryId, setCategoryId] = useState<string>(
    initialValues.categoryId,
  );
  const [filters, setFilters] = useState<string[]>([]);
  const [address, setAddress] = useState<GeoAddress>(initialValues.address);

  const [note, setNote] = useState<string>(initialValues.note);
  const [orderMethod, setOrderMethod] = useState<OrderMethod>(
    initialValues.orderMethod,
  );
  const [gratuity, setGratuity] = useState<GratuityType>(
    initialValues.gratuity,
  );
  const [calculatingCart, setCalculatingCart] = useState<boolean>(
    initialValues.calculatingCart,
  );
  const [orderConfirmationDisabled, setOrderConfirmationDisabled] =
    useState<boolean>(initialValues.orderConfirmationDisabled);
  const [deliveryDate, setDeliveryDate] = useState<Moment>(
    initialValues.deliveryDate,
  );
  const [deliveryTime, setDeliveryTime] = useState<DeliveryTime>(
    initialValues.deliveryTime,
  );
  const [deliverTo, setDeliverTo] = useState<DeliverTo>(
    initialValues.deliverTo,
  );
  const [numberOfGuests, setNumberOfGuests] = useState<string>(
    initialValues.numberOfGuests,
  );
  const [checkoutErrors, setCheckoutErrors] = useState<CheckoutErrors>(
    INITIAL_CHECKOUT_ERRORS,
  );

  const [preLoginMenuItem, setPreLoginMenuItem] = useState(
    initialValues.preLoginMenuItem,
  );

  const existingAddress = getExistingAddress({
    existingAddresses: me?.customerInfo?.addresses.map(
      ({ formattedAddress, ...rest }) => ({
        ...rest,
        label: formattedAddress || '',
      }),
    ),
    currentAddress: address,
  });

  const isDelivery = orderMethod === OrderMethod.Delivery;
  const { data: cartData, refetch: customerCartCost } =
    useCustomerCartCostsQuery({
      fetchPolicy: 'cache-and-network',
      skip: !me,
      variables: {
        orderMethod: orderMethod || OrderMethod.Delivery,
        addressId: existingAddress?.id,
        placeId: !existingAddress ? address?.placeId : undefined,
      },
      // if there was an issue with calculation of the cost - forbid to pay for order until it's resolved
      onError: (e) => {
        message.error(e.message);
        setOrderConfirmationDisabled(true);
        setCalculatingCart(false);
      },
      // once the issue was resolved - allow to pay for order
      onCompleted: () => {
        setOrderConfirmationDisabled(false);
        setCalculatingCart(false);
      },
    });

  const summary = orderSummary(
    {
      ...ORDER_SUMMARY_DEFAULT_VALUES,
      ...cartData?.customerCartCosts,
    },
    isDelivery,
    gratuity,
  );

  const basicCheckoutValidation = () => {
    if (cartItemsCount === 0) {
      message.error(ERROR_MESSAGE.CART_ITEMS.NO_ITEMS_CART);

      return false;
    }

    if (!orderMethod) {
      message.error(ERROR_MESSAGE.ORDER_METHOD_REQUIRED);

      return false;
    }

    if (
      !address?.id &&
      !address?.placeId &&
      orderMethod === OrderMethod.Delivery
    ) {
      message.error(ERROR_MESSAGE.INVALID_ADDRESS);

      return false;
    }

    if (!deliveryDate) {
      message.error(ERROR_MESSAGE.DATE_REQUIRED);

      return false;
    }

    if (!deliveryTime) {
      message.error(ERROR_MESSAGE.TIMESLOT_REQUIRED);

      return false;
    }

    return true;
  };

  useEffect(() => {
    if (me && !address) {
      const address = getDefaultAddress(
        me?.customerInfo?.defaultAddressId ?? '',
        me?.customerInfo?.addresses,
      );
      setAddress(address);
    }
  }, [me, address, customerCartCost, customerCartQuery]);

  useEffect(() => {
    setCalculatingCart(true);
    customerCartCost();
    customerCartQuery();
  }, [address?.id, orderMethod, customerCartCost, customerCartQuery]);

  const resetCartContext = () => {
    setDeliverTo(INITIAL_VALUES.deliverTo);
    setDeliveryDate(INITIAL_VALUES.deliveryDate);
    setDeliveryTime(INITIAL_VALUES.deliveryTime);
    setGratuity(INITIAL_VALUES.gratuity);
    setNumberOfGuests(INITIAL_VALUES.numberOfGuests);
    sessionStorage.clear();
  };

  // if date is not provided, order for now is taken by default
  const deliveryDateTime =
    deliveryTime === OrderDeliveryTimeSpecialOption.Now
      ? undefined
      : moment(deliveryTime).utc().toDate();

  const handleSetDeliveryTime = (value: any) => {
    // category and restaurant might not be available in the new timeslot, reset
    setCategoryId(CATEGORY_OPTIONS.First);
    setKitchenId(KITCHEN_OPTIONS.All);
    withUpdateSessionStorage({
      key: UrlKey.deliveryTime,
      query,
      push,
    })(setDeliveryTime)(value);
  };

  return (
    <AppContext.Provider
      value={{
        me,
        address,
        setAddress: withUpdateSessionStorage({
          key: UrlKey.address,
          query,
          push,
        })(setAddress),
        note,
        setNote,
        orderMethod,
        setOrderMethod: withUpdateSessionStorage({
          key: UrlKey.orderMethod,
          query,
          push,
        })(setOrderMethod),
        gratuity,
        setGratuity,
        deliveryDate,
        setDeliveryDate: withUpdateSessionStorage({
          key: UrlKey.deliveryDate,
          query,
          push,
        })(setDeliveryDate),
        deliveryTime,
        setDeliveryTime: handleSetDeliveryTime,
        kitchenId,
        setKitchenId: withUpdateSessionStorage({
          key: UrlKey.kitchenId,
          query,
          push,
        })(setKitchenId),
        categoryId,
        setCategoryId: withUpdateSessionStorage({
          key: UrlKey.categoryId,
          query,
          push,
        })(setCategoryId),
        preLoginMenuItem,
        setPreLoginMenuItem: withUpdateSessionStorage({
          key: UrlKey.preLoginMenuItem,
          query,
          push,
        })(setPreLoginMenuItem),
        deliveryDateTime,
        deliverTo,
        setDeliverTo,
        numberOfGuests,
        setNumberOfGuests,
        basicCheckoutValidation,
        checkoutErrors,
        setCheckoutErrors,
        summary,
        calculatingCart,
        setCalculatingCart,
        platform,
        // @FIXME: seems useless, always comes empty, we should rather use "query" property from Next Router to path params over
        extraParams,
        cartItemsCount,
        customerCart,
        customerCartLoading,
        isMobile,
        filters,
        setFilters,
        resetCartContext,
        orderConfirmationDisabled,
        setOrderConfirmationDisabled,
        refetchCartCost: customerCartCost,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppContextProvider;
