import { Moment } from 'moment-timezone';

import {
  UserQuery,
  OrderMethod,
  CustomerCartQuery,
} from '@codegen/generated/graphql';
import { ExtrasValue } from '@containers/MenuItemModal/types';
import {
  CheckoutErrors,
  DeliverTo,
  GeoAddress,
  GratuityType,
  OverallSummary,
  Platform,
} from '@utils/types';

export enum OrderDeliveryTimeSpecialOption {
  Now = 'Now',
}

export type DeliveryTime = Date | OrderDeliveryTimeSpecialOption.Now;
export interface Props {
  me?: UserQuery['me'] | null;

  address: GeoAddress;
  setAddress: (address: GeoAddress) => void;

  note: string;
  setNote: (value: string) => void;

  orderMethod: OrderMethod;
  setOrderMethod: (method: OrderMethod) => void;

  gratuity: GratuityType;
  setGratuity: (value: GratuityType) => void;

  deliveryDate: Moment;
  setDeliveryDate: (value: Moment) => void;

  deliveryTime: DeliveryTime;
  setDeliveryTime: (value: DeliveryTime) => void;
  deliveryDateTime?: Date;

  deliverTo: DeliverTo;
  setDeliverTo: (value: DeliverTo) => void;

  numberOfGuests: string;
  setNumberOfGuests: (value: string) => void;

  basicCheckoutValidation: () => boolean;

  checkoutErrors: CheckoutErrors;
  setCheckoutErrors: (value: CheckoutErrors) => void;

  summary: OverallSummary;

  calculatingCart: boolean;
  setCalculatingCart: (value: boolean) => void;

  orderConfirmationDisabled: boolean;
  setOrderConfirmationDisabled: (value: boolean) => void;

  platform: Platform;
  extraParams: string;

  cartItemsCount: number;
  customerCart?: NonNullable<
    NonNullable<CustomerCartQuery['me']>['customerInfo']
  >['cart'];
  customerCartLoading: boolean;
  isMobile: boolean;

  filters: string[];
  setFilters: (value: string[]) => void;

  kitchenId?: string;
  setKitchenId: (value?: string) => void;

  categoryId?: string;
  setCategoryId: (value?: string) => void;

  resetCartContext: () => void;
  refetchCartCost: () => void;

  preLoginMenuItem: { id: string; extras: ExtrasValue[] };
  setPreLoginMenuItem: (value: { id: string; extras: ExtrasValue[] }) => void;
}
